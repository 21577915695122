$('#reg-submit-account').click(function (e) {
    e.preventDefault();

    //Dati
    var fd = new FormData($('#form_user_registration')[0]);
    fd.append('ajax_action', 'btn-registration');

    var url = $('#form_user_registration').attr('action');

    $.ajax({
        type: "POST",
        url: url,
        dataType: 'json',
        data: fd,
        contentType: false,
        processData: false,
        success: function (response) {
            $('#form_user_registration_errors').html('');
            if (response.validation) {
                $('#form_user_registration').submit();
            }
            else {
                if (response.url_redirect) {
                    window.location = response.url_redirect;
                }
                else {
                    $('#form_user_registration_errors').html(response.template);
                }
            }
        },
        error: function (response, status, error) {
            console.log('Si &egrave; verificato un errore! Riprova');
        }
    });
});