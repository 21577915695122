/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

// is element in viewport function
function isElementInViewport(el) {

    // if using jquery
    if (typeof jQuery === 'function' && el instanceof jQuery) {
        el = el[0];
    }

    // get bounding element rect
    var rect = el.getBoundingClientRect();

    // if is in view check for bottom not above the viewport and top not over
    return rect.bottom > 0 && rect.top < $(window).height();
}

// responsive ajax images plugin
(function ($, window, document, undefined) {

    $.fn.responsive_ajax_image = function () {

        return this.each(function () {

            var $el = $(this),
                el_w = $el.width(),
                img_w = $el.data('imgwidth'),
                img_h = $el.data('imgheight'),
                img_alt = $el.data('imgalt'),
                img_url = $el.data('imgsrc'),
                img_ratio = img_h / img_w,
                $ajax_image = $('<img>'),
                visible = false,
                loaded = false,
                setElHeight = function () {
                    $el.css('height', el_w * img_ratio);
                };

            // set image to load and height based on ratio
            setElHeight();

            $(window).resize(function () {

                //refresh height of empty div on resize
                if (!loaded) {
                    el_w = $el.width();
                    setElHeight();
                }

            });

            $(window).on('DOMContentLoaded load resize scroll', function () {

                if (visible === true) {
                    return false;
                } else {
                    visible = isElementInViewport($el);
                    if (visible && !$el.hasClass('loaded')) {
                        $el.addClass('loading');
                        $ajax_image.load(function () {
                            $ajax_image.appendTo($el);
                            $el.removeAttr('style');
                            loaded = true;
                            setTimeout(
                                function () {
                                    $el.addClass('loaded');
                                }, 100
                            );
                        });
                        $ajax_image.attr({
                            src: img_url,
                            alt: img_alt,
                            width: img_w,
                            height: img_h
                        });
                    }
                }
            });

            return this;
        });
    };

}(jQuery, window, document));

//Imposto l'url de lsito per le chiamate ajax
var site_url = document.location.protocol + '//' + document.location.host;

(function ($) {

    $.ajaxSetup({
        headers: {'X-CSRF-TOKEN': $('meta[name=_token]').attr('content')}
    });

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        'utils': {
            // Parse argument from url
            parseArg: function (val) {
                var result = false,
                    tmp = [];
                location.search
                    //.replace ( "?", "" )
                    // this is better, there might be a question mark inside
                    .substr(1).split('&').forEach(function (item) {
                    tmp = item.split('=');
                    if (tmp[0] === val) {
                        result = decodeURIComponent(tmp[1]);
                    }
                });
                return result;
            },
            // Imagesloaded placeholder
            imgPlaceholder: function (placeholder_item) {
                // Check if it's not already loaded
                if (placeholder_item.hasClass('toload') && !placeholder_item.hasClass('loaded')) {
                    // Get real image path and title
                    $image_path = placeholder_item.data('toload');
                    $image_title = placeholder_item.data('toload-title');
                    // Create real image element
                    $real_image = $('<img>').addClass('real').attr('src', $image_path).attr('alt', $image_title);
                    // Append the element in placeholder
                    placeholder_item.append($real_image);
                    // Check and wait for imagesLoaded
                    placeholder_item.imagesLoaded().progress(function (imgLoad, image) {
                        placeholder_item.removeClass('toload');
                        if (!image.isLoaded) {
                            // Remove the class so it will not be triggered again
                            placeholder_item.addClass('is-broken');
                        }
                    });
                }
                return true;
            },
            loadmoreAjax: function (trigger, container_row) {

                trigger.addClass('loading');

                var to_exclude = $.map(
                    container_row.find('.courses-list-item-container'),
                    function (el) {
                        return $(el).data('postid');
                    }
                );
                $.ajax('/wp-admin/admin-ajax.php', {
                    type: 'post',
                    data: {
                        action: 'loadmore_courses',
                        cur_term: trigger.data('term'),
                        to_exclude: to_exclude
                    },
                    dataType: 'html',
                    success: function (result, status, xhrRequest) {
                        if (result) {
                            container_row.append(result);
                            $('.responsive-ajax-image').responsive_ajax_image();
                            $(window).trigger('scroll');
                            var total_posts = trigger.data('total');
                            if (container_row.find('.courses-list-item-container').length === total_posts) {
                                trigger.remove();
                            } else {
                                trigger.removeClass('loading');
                            }
                        }
                    },
                    error: function (xhrRequest, status, errorMessage) {
                        return false;
                    }
                });
            },
            inputNumber: function () {
                $('.number-input-container').each(function () {
                    if (!$(this).hasClass('initialized')) {
                        var arrow_up = $(this).find('.arrow-up');
                        var arrow_down = $(this).find('.arrow-down');
                        var input = $(this).find('input[type=text]');
                        var min = input.data('min');
                        var max = input.data('max');

                        var num_up = function (event) {
                            event.preventDefault();
                            var value = input.val();
                            var value_num = parseInt(value);
                            if (value_num < max) {
                                input.val(value_num + 1);
                                input.trigger('change');
                            }
                        };
                        var num_down = function (event) {
                            event.preventDefault();
                            var value = input.val();
                            var value_num = parseInt(value);
                            if (value_num > min) {
                                input.val(value_num - 1);
                                input.trigger('change');
                            }
                        };
                        arrow_up.click(num_up);
                        arrow_down.click(num_down);

                        var num_check = function () {
                            var value = input.val();
                            var value_num = parseInt(value.replace(/^\D+/g, ''));
                            value_num = value_num ? value_num : min;
                            if (value_num < min) {
                                input.val(min);
                            } else if (value_num > max) {
                                input.val(max);
                            } else {
                                input.val(value_num);
                            }
                        };
                        input.keyup(function (e) {
                            if (e.keyCode === 38) {
                                e.preventDefault();
                                num_up(e);
                            } else if (e.keyCode === 40) {
                                e.preventDefault();
                                num_down(e);
                            } else {
                                num_check();
                            }
                        });
                        $(this).addClass('initialized');
                    }
                });
            },
            xy: function () {
                var w = window,
                    d = document,
                    e = d.documentElement,
                    g = d.getElementsByTagName('body')[0],
                    x = w.innerWidth || e.clientWidth || g.clientWidth,
                    y = w.innerHeight || e.clientHeight || g.clientHeight;
                return {x: x, y: y};
            },

            breakpoints: function () {
                var xs = 480,
                    sm = 768,
                    md = 992,
                    lg = 1200;
                return {xs: xs, sm: sm, md: md, lg: lg};
            }
        },
        'common': {
            init: function () {

                var check_coupon = $('.coupon_code_input');
                check_coupon.keyup(function (e) {
                    var value = $(this).val();
                    var message_container = $(this).parent().parent().find('.coupon-code-message').eq(0);
                    if (value.length >= 20) {
                        $.ajax({
                            type: 'POST',
                            url: variables.ajax_url,
                            data: 'action=check_coupon&coupon_code=' + value,
                            dataType: 'json',
                            success: function (data) {
                                message_container.html('<div align=\'center\' class=\'alert alert-' + (data.res === '1' ? 'success' : 'danger') + '\'>' + data.message + '</div>');
                                message_container.fadeIn('slow');
                            }
                        });
                    } else {
                        message_container.fadeOut('slow');
                        message_container.empty();
                    }
                });

                // ajax images
                $('.responsive-ajax-image').responsive_ajax_image();

                // Load all image placeholders on page init
                $('.image-placeholder:visible').each(function () {
                    Sage.utils.imgPlaceholder($(this));
                });

                $('.aside-carousel').owlCarousel({
                    nav: false,
                    loop: true,
                    dots: false,
                    items: 1,
                    margin: 0,
                    stagePadding: 0,
                    autoplay: true,
                    autoplayTimeout: 5000,
                    animateOut: 'fadeOut',
                    mouseDrag: false,
                    touchDrag: false,
                    pullDrag: false,
                    freeDrag: false
                });

                // Load all image placeholders on carousel slide
                $('.carousel').on('slid.bs.carousel', function () {
                    $(this).find('.image-placeholder:visible').each(function () {
                        Sage.utils.imgPlaceholder($(this));
                    });
                });

                $('.coupon-link').click(function (e) {
                    e.preventDefault();
                    $(this).parent().find('.coupon-code-container').show();
                    $(this).hide();
                });

                var photogalleries = $('.photogallery-inner');
                photogalleries.each(function () {
                    var photogallery = $(this);
                    photogallery.on('click', 'a.photogallery-image-preview', function (event) {
                        event.preventDefault();
                        var link = $(this)[0],
                            options = {index: link, event: event, container: '#blueimp-gallery'},
                            links = photogallery.find('a');
                        blueimp.Gallery(links, options);
                    });
                });

                var container_row = $('#courses_row'),
                    trigger = $('#loadmore');

                trigger.click(function (e) {
                    e.preventDefault();
                    Sage.utils.loadmoreAjax(trigger, container_row);
                });

                $schedule_form = $('#course-schedule-form');
                $schedule_form.submit(function (e) {
                    e.preventDefault();
                    var input = $schedule_form.find('input[type=email]');
                    var course_id = $schedule_form.find('input[name=course_id]');
                    if (input && input.val().trim() !== '') {
                        //Dati
                        var fd = new FormData($('#course-schedule-form')[0]);
                        fd.append('ajax_action', 'corso-waiting-list-subscribe');
                        fd.append('course_schedule_email', input.val().trim());

                        var url = $('#course-schedule-form').attr('action');

                        $.ajax({
                            type: 'POST',
                            url: url,
                            dataType: 'json',
                            data: fd,
                            contentType: false,
                            processData: false,
                            success: function (response) {
                                $('#course_schedule_errors').html('');
                                if (response.validation) {
                                    $schedule_form.html('');
                                    $schedule_form.append('<div align=\'center\' class=\'alert alert-success\'>' + response.error + '</div>');
                                } else {
                                    $('#course_schedule_errors').html(response.template);
                                }
                            },
                            error: function (response, status, error) {
                                console.log('Si &egrave; verificato un errore! Riprova');
                            }
                        });
                    }
                    return false;
                });

                $('#home_select_categories').select2({
                    minimumResultsForSearch: -1, // disable inner search
                    //width: 'resolve'
                }).change(function () {
                    location.href = $(this)[0].selectedOptions[0].dataset.href;
                });

                $('.buy_icon').click(function (e) {
                    e.preventDefault();

                    // Dati articolo
                    var article_id = Number($(this).attr('data-article-id'));
                    var article_qta = Number($('#product_quantity_' + article_id).val());
                    var fd = "_token=" + $('meta[name=_token]').attr('content') + "&article_id=" + article_id + "&article_qta=" + article_qta;

                    $.ajax({
                        url: site_url + '/cart/article/add',
                        type: "POST",
                        data: fd,
                        success: function (response) {
                            if (!response.validation) {
                                if (response.statusCode === 501) {
                                    $('#fancybox-content > p').val('').text(response.message);
                                    $('#a-fancybox-content').click();
                                    //alert(response.message);
                                } else {
                                    $('#fancybox-content > p').val('').text(response.message);
                                    $('#a-fancybox-content').click();
                                    //alert(response.message);
                                }
                            } else {
                                //$('#aside_cart_summary').html(response.template);
                                $('#cart_tot_articles').text(response.cart_tot_articles);

                                $('#fancybox-content > p').val('').text(response.message);
                                $('#a-fancybox-content').click();
                                //alert(response.message);
                            }

                        },
                        error: function (response, status, error) {
                            //console.log(response);
                            console.log('Si &egrave; verificato un errore! Riprova');
                        }
                    });
                });
                $('#reg-submit').click(function (e) {
                    e.preventDefault();

                    //Dati
                    var fd = new FormData($('#form_user_registration')[0]);
                    fd.append('ajax_action', 'btn-registration');

                    var url = $('#form_user_registration').attr('action');

                    $.ajax({
                        type: "POST",
                        url: url,
                        dataType: 'json',
                        data: fd,
                        contentType: false,
                        processData: false,
                        success: function (response) {
                            $('#form_user_registration_errors').html('');
                            if (response.validation) {
                                $('#form_user_registration').submit();
                            } else {
                                if (response.url_redirect) {
                                    window.location = response.url_redirect;
                                } else {
                                    $('#form_user_registration_errors').html(response.template);
                                }
                            }
                        },
                        error: function (response, status, error) {
                            console.log('Si &egrave; verificato un errore! Riprova');
                        }
                    });
                });

                var catBtn = $('.aside-cat-maintitle button');

                function auto_collapse_shop_cats() {
                    if (catBtn.length > 0) {
                        if (Sage.utils.xy().x >= Sage.utils.breakpoints().md) {
                            if (catBtn.hasClass('collapsed')) {
                                catBtn.click();
                            }
                        }
                    }
                }

                auto_collapse_shop_cats();
                $(window).resize(function () {
                    auto_collapse_shop_cats();
                });
            },
            finalize: function () {
                //=require _newsletter.init.js

                // JavaScript to be fired on all pages, after page specific JS is fired
                $('[data-toggle="tooltip"]').tooltip();
                Sage.utils.inputNumber();
            }
        },
        'page_template_template_coupon': {
            init: function () {
                $('.coupon-type').click(function () {
                    $('.coupon-type-data').hide();
                    $('#' + $(this).attr('id') + '_data').show();
                });

                Sage.page_template_template_coupon.populate_schedule();
                $('#coupon_course').change(Sage.page_template_template_coupon.populate_schedule);
            },
            populate_schedule: function () {
                var course_id = $('#coupon_course').val();
                var schedule_data = $('#coupon_type_course_schedule_data');
                var schedule_select = $('#coupon_schedule');
                var current_value = schedule_select.data('value');

                schedule_data.hide();
                schedule_select.html('<option value="">Seleziona la data...</option>');

                if (course_id !== '') {
                    $.ajax({
                        type: 'POST',
                        url: variables.ajax_url,
                        data: 'action=next_schedules&course_id=' + course_id,
                        dataType: 'json',
                        success: function (data) {
                            if (data.res === '1') {
                                $.each(data.items, function (index, item) {
                                    schedule_select.append('<option value="' + item.id + '" ' + (current_value === item.id ? 'selected' : '') + '>' + item.data + '</option>');
                                });
                                schedule_data.show();
                            }
                        }
                    });
                }
            }
        },
        'single_course': {

            init: function () {
                //=require _schedule_registration.init.js
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
                $('.course-schedule-data-step-button').click(function (e) {
                    e.preventDefault();
                    var container = $(this).closest('.course-schedule-data-side');
                    var active = container.find('.course-schedule-data-step.active').eq(0);
                    var next = active.next();
                    active.removeClass('active');
                    next.addClass('active');

                    $('.course-schedule-box-date').hide();
                });
                $('.schedule-friends').change(function () {
                    var val = parseInt($(this).val());
                    if (val > 0) {
                        $(this).closest('.course-schedule-data-step').find('.smallest.alert').show();
                        $(this).closest('.course-schedule-data-step').find('#n_friends_attention').text(val + 1);
                    } else {
                        $(this).closest('.course-schedule-data-step').find('.smallest.alert').hide();
                    }
                });

                function getCourseScheduleBoxTop() {
                    return $(".course-schedule-box").first().offset().top - 100;
                }

                $(window).scroll(function () {
                    if ($(window).width() < 992) {
                        if ($(window).scrollTop() >= getCourseScheduleBoxTop()) {
                            $(".buy-course-bottom-bar").addClass("buy-course-bottom-bar-hidden");
                        } else {
                            $(".buy-course-bottom-bar").removeClass("buy-course-bottom-bar-hidden");
                        }
                    }
                });

                $("#buy-course-bottom-bar-button").click(function () {
                    $("html, body").animate(
                        {
                            scrollTop: getCourseScheduleBoxTop()
                        },
                        1000,
                        function () {
                            setTimeout(function () {
                                $(".buy-course-bottom-bar").addClass("buy-course-bottom-bar-hidden");
                            }, 100);
                        });
                });
            }
        },
        'blog': {
            init: function () {

            },
            finalize: function () {
                $(window).on('load', function () {
                    $('.posts-grid').masonry({
                        columnWidth: '.grid-sizer',
                        itemSelector: '.grid-item',
                        percentPosition: true
                    });
                });
            }
        },
        'photogallery': {
            init: function () {
                $('#photo_filter_mobile').select2({
                    minimumResultsForSearch: -1, // disable inner search
                    width: 'resolve'
                });

                $('a.fb-xfbml-parse-ignore').click(function (e) {
                    e.preventDefault();
                });
            },
            finalize: function () {

            }
        },
        'video': {
            init: function () {

            },
            finalize: function () {
                $(window).on('load', function () {
                    $('.videos-grid').masonry({
                        columnWidth: '.grid-sizer',
                        itemSelector: '.grid-item',
                        percentPosition: true
                    });
                });
            }
        },
        'account': {
            init: function () {
                //=require _customer_registration.init.js

                var reg_errors = $('#reg_errors');
                if (reg_errors.length) {
                    $('html, body').animate({scrollTop: reg_errors.offset().top}, 500);
                }

                var show_registration_button = $('#show_registration_button');
                show_registration_button.click(function (e) {
                    e.preventDefault();
                    $('.form-container-bottom').slideDown('slow');
                    $('html, body').animate({
                        scrollTop: $('#regform').offset().top - 100
                    }, 800);
                });

                var dtDate = new Date(),
                    dtBox = $('<div>').attr('id', 'dtBox').appendTo('body');
                dtDate.setHours('9', '0');
                $(dtBox).DateTimePicker({
                    defaultDate: dtDate.setYear(dtDate.getYear() - 18),
                    dateSeparator: '/',
                    shortDayNames: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
                    fullDayNames: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Sabato', 'Domenica'],
                    dateTimeFormat: 'dd/MM/yyyy hh:mm',
                    dateFormat: 'dd/MM/yyyy',
                    shortMonthNames: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
                    fullMonthNames: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
                    titleContentDate: 'Imposta data',
                    titleContentTime: 'Imposta ora',
                    titleContentDateTime: 'Imposta data e ora',
                    buttonsToDisplay: ['SetButton'],
                    setButtonContent: 'Ok',
                    clearButtonContent: 'Reset',
                    setValueInTextboxOnEveryClick: true,
                    minuteInterval: 5,
                    isPopup: false,
                    animationDuration: 400
                });
            }
        },
        'post_type_archive_photogallery': {
            init: function () {

                function get_gallery_category(filter) {
                    var len = $('.photogallery-list-content').length;
                    $('.photogallery-list-content').each(function (index) {
                        var classes = $(this).attr('class');
                        if (filter === 'all' ? true : classes.split(' ').indexOf('filter-' + filter) >= 0) {
                            $(this).show();
                        } else {
                            $(this).hide();
                        }
                        if (index === len - 1) {
                            setTimeout(function () {
                                $(window).trigger('scroll').trigger('resize');
                            }, 500);
                        }
                    });
                }

                $('.photo-filter a').click(function (e) {
                    e.preventDefault();
                    var filter = $(this).data('filter');
                    get_gallery_category(filter);
                });

                $('#photo_filter_mobile').change(function () {
                    var filter = $(this).val();
                    get_gallery_category(filter);
                });

                $(window).load(function () {
                    $('.photogallery-list-content-loading').removeClass('photogallery-list-content-loading');
                    var arg = Sage.utils.parseArg('arg');
                    if (arg !== false) {
                        $('.photo-filter a[data-filter=' + arg + ']').click();
                    }
                });
            }
        },
        'article_show': {
            init: function () {
                $('.quantity-minus').click(function () {
                    var article_id = Number($(this).attr('data-article-id'));
                    var current_quantity = $('#product_quantity_' + article_id).val();
                    current_quantity--;
                    if (current_quantity > 0) {
                        $('#product_quantity_' + article_id).val(current_quantity);
                    }
                });
                $('.quantity-plus').click(function () {
                    var article_id = Number($(this).attr('data-article-id'));
                    var current_quantity = $('#product_quantity_' + article_id).val();
                    current_quantity++;
                    $('#product_quantity_' + article_id).val(current_quantity);
                });
                $('.article-image-list.owl-carousel').owlCarousel({
                    items: 1,
                    nav: false,
                    dots: false,
                    autoplay: true,
                    loop: true,
                    margin: 0,
                    autoplayHoverPause: true,
                    stagePadding: 0
                });
                $('[data-fancybox="gallery"]').fancybox({
                    loop: true,
                    buttons: [
                        "close"
                    ],
                    infobar: false,
                });
            }
        },
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
