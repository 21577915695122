$('.schedule-subscribe-submit').click(function (e) {
    e.preventDefault();

    // evito clic multipli durante l'invio
    var _sendingScheduleSubscription = window.sendingScheduleSubscription || false;
    if (_sendingScheduleSubscription) {
        return;
    }
    window.sendingScheduleSubscription = true;

    //Dati
    var schedule_id = $(this).data("schedule");
    var fd = new FormData($('#form_schedule_registration_' + schedule_id)[0]);
    fd.append('ajax_action', 'schedule-subscribe-validation');

    var url = $('#form_schedule_registration_' + schedule_id).attr('action');

    $.ajax({
        type: "POST",
        url: url,
        dataType: 'json',
        data: fd,
        contentType: false,
        processData: false,
        success: function (response) {
            $('#form_schedule_registration_errors_' + schedule_id).html('');
            if (response.validation) {
                $('#form_schedule_registration_' + schedule_id).append('<input type="hidden" name="schedule-subscribe-reg" value="1" />');
                $('#form_schedule_registration_' + schedule_id).submit();
            }
            else {
                if (response.url_redirect) {
                    window.location = response.url_redirect;
                }
                else {
                    window.sendingScheduleSubscription = false;
                    $('#form_schedule_registration_errors_' + schedule_id).html(response.template);
                }
            }
        },
        error: function (response, status, error) {
            window.sendingScheduleSubscription = false;
            console.log('Si &egrave; verificato un errore! Riprova');
        }
    });
});

$('.btn-waiting-send-full').click(function (e) {
    e.preventDefault();

    alert('ciao');
    //Dati
    var schedule_id = $(this).data("schedule");
    var corso_id = $(this).data("corso");
    var fd = new FormData($('#form_schedule_registration_' + schedule_id)[0]);
    fd.append('ajax_action', 'corso-waiting-list-subscribe-full');

    var url = $('#form_schedule_registration_' + schedule_id).attr('action');

    $.ajax({
        type: "POST",
        url: url,
        dataType: 'json',
        data: fd,
        contentType: false,
        processData: false,
        success: function (response) {
            $('#course_schedule_errors-full-' + schedule_id).html('');
            $('#box-waiting-subscribe-success-full-' + schedule_id).hide();
            if (response.validation) {
                $('#box-waiting-subscribe-input-full-' + schedule_id).hide();
                $('#box-waiting-subscribe-success-full-' + schedule_id).show();
                $('#box-waiting-subscribe-success-full-' + schedule_id).html(response.error);
            }
            else {
                $('#course_schedule_errors-full-' + schedule_id).html(response.template);
            }
        },
        error: function (response, status, error) {
            console.log('Si &egrave; verificato un errore! Riprova');
        }
    });
});

$('.btn-waiting-send').click(function (e) {
    e.preventDefault();

    //Dati
    var fd = new FormData($('#course-schedule-form')[0]);
    fd.append('ajax_action', 'corso-waiting-list-subscribe');

    var url = $('#form_schedule_registration').attr('action');

    $.ajax({
        type: "POST",
        url: url,
        dataType: 'json',
        data: fd,
        contentType: false,
        processData: false,
        success: function (response) {
            $('#course_schedule_errors').html('');
            $('#box-waiting-subscribe-success').hide();
            if (response.validation) {
                $('#box-waiting-subscribe-input').hide();
                $('#box-waiting-subscribe-success').show();
                $('#box-waiting-subscribe-success').html(response.error);
            }
            else {
                $('#course_schedule_errors').html(response.template);
            }
        },
        error: function (response, status, error) {
            console.log('Si &egrave; verificato un errore! Riprova');
        }
    });
});

$('#schedule_data_select').on('change', function() {
    $('.course-schedule-data-side').hide();
    $('.lessons-schedule-data-side').hide();
    $('#course-schedule-data-side-' + this.value).show();
    $('#lessons-schedule-data-side-' + this.value).show();
});